import styled from 'styled-components'
import { Flex, Box, Heading, NotificationDot, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import GlobalSettings from 'components/Menu/GlobalSettings'
import { useExpertModeManager } from 'state/user/hooks'
import { SettingsMode } from '../../../components/Menu/GlobalSettings/types'

interface Props {
  title: string
  subtitle: string
  noConfig?: boolean
  setIsChartDisplayed?: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed?: boolean
  hasAmount: boolean
  onRefreshPrice: () => void
}

const CurrencyInputContainer = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 24px 18px 20px;
  width: 100%;
  // border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`

const CurrencyInputHeader: React.FC<React.PropsWithChildren<Props>> = ({ title, subtitle }) => {
  const [expertMode] = useExpertModeManager()
  const { isMobile } = useMatchBreakpoints()

  return (
    <CurrencyInputContainer>
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Flex flexDirection="column" width="100%" mr={18}>
          <Heading as="h2">{title}</Heading>
          <Text color="subTitle" pt="10px" fontSize={isMobile ? '14px' : '16px'}>
            {subtitle}
          </Text>
        </Flex>

        <Box style={{ position: 'relative', left: '-10px', top: isMobile ? '0px' : '-4px' }}>
          <NotificationDot show={expertMode}>
            <GlobalSettings mr="0" mode={SettingsMode.SWAP_LIQUIDITY} />
          </NotificationDot>
        </Box>
      </Flex>
    </CurrencyInputContainer>
  )
}

export default CurrencyInputHeader
