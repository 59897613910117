import styled, { useTheme } from 'styled-components'
import { Text, Image, useMatchBreakpoints } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { AutoColumn, ColumnCenter } from 'components/Layout/Column'
import { Trade, Currency, TradeType } from '@pancakeswap/sdk'

const Wrapper = styled.div`
  width: 100%;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 30px 0;
`

function ConfirmationPendingContent({ trade }: { trade: Trade<Currency, Currency, TradeType> }) {
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()
  const { isDark } = useTheme()

  // text to show while loading
  const pendingText = t('Swapping %amountA% %symbolA% for %amountB% %symbolB%', {
    amountA: trade?.inputAmount?.toFixed(4) ?? '',
    symbolA: trade?.inputAmount?.currency?.symbol ?? '',
    amountB: trade?.outputAmount?.toFixed(4) ?? '',
    symbolB: trade?.outputAmount?.currency?.symbol ?? '',
  })

  return (
    <Wrapper>
      <Text fontSize={isMobile ? '14px' : '16px '} color="subTitle" textAlign="center">
        {t('Confirm this transaction in your wallet')}
      </Text>
      <ConfirmedIcon>
        {/* <Spinner /> */}
        <Image width={100} height={110} src={isDark ? '/images/loading.gif' : '/images/loading-light.gif'} />
      </ConfirmedIcon>
      <AutoColumn gap="8px" justify="center">
        <Text fontSize={isMobile ? '18px' : '22px '}>{t('Waiting For Confirmation')}</Text>
        <AutoColumn justify="center">
          <Text fontSize={isMobile ? '14px' : '18px '} color="subTitle" textAlign="center">
            {pendingText}
          </Text>
        </AutoColumn>
      </AutoColumn>
    </Wrapper>
  )
}

export default ConfirmationPendingContent
