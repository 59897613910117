import { Token } from '@pancakeswap/sdk'
import { robinTestnetWarningTokens } from 'config/constants/warningTokens'

const { pokemoney, free, safemoon } = robinTestnetWarningTokens

interface WarningTokenList {
  [key: string]: Token
}

const SwapWarningTokens = <WarningTokenList>{
  pokemoney,
  free,
  safemoon,
}

export default SwapWarningTokens
