import { Price, Currency } from '@pancakeswap/sdk'
import { Text, ExchangeIcon, useMatchBreakpoints } from '@pancakeswap/uikit'
// import { StyledBalanceMaxMini } from './styleds'

interface TradePriceProps {
  price?: Price<Currency, Currency>
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
  const formattedPrice = showInverted ? price?.toFixed(4) : price?.invert()?.toFixed(4)
  const { isMobile } = useMatchBreakpoints()

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = showInverted
    ? `${price?.quoteCurrency?.symbol} per ${price?.baseCurrency?.symbol}`
    : `${price?.baseCurrency?.symbol} per ${price?.quoteCurrency?.symbol}`

  return (
    <Text
      style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
      fontSize={isMobile ? '14px' : '16px'}
    >
      {show ? (
        <>
          {formattedPrice ?? '-'} {label}
          {/* <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
            <div className="icon-bg-shadow" style={{ marginLeft: '12px', padding: '2px 7px' }}>
              <ExchangeIcon width="10px" />
            </div>
            <div className="icon-bg-shadow" style={{marginLeft: "12px", padding: "2px 7px"}}>
              <img src="./images/icon/exchange-horizontal.png" alt="exchange-horizontal icon" style={{maxWidth: "none"}} />
            </div>
          </StyledBalanceMaxMini> */}
        </>
      ) : (
        '-'
      )}
    </Text>
  )
}
