import { useMemo } from 'react'
import { Trade, TradeType, CurrencyAmount, Currency } from '@pancakeswap/sdk'
import { Button, Text, Box, Flex, ErrorIcon, IconButton, ArrowBackIcon, useMatchBreakpoints } from '@pancakeswap/uikit'
import { Field } from 'state/swap/actions'
import { useTranslation } from '@pancakeswap/localization'
import { computeTradePriceBreakdown, warningSeverity } from 'utils/exchange'
import { AutoColumn } from 'components/Layout/Column'
import { CurrencyLogo } from 'components/Logo'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import truncateHash from 'utils/truncateHash'
import { TruncatedText, SwapShowAcceptChanges } from './styleds'

export default function SwapModalHeader({
  trade,
  slippageAdjustedAmounts,
  isEnoughInputBalance,
  recipient,
  showAcceptChanges,
  onAcceptChanges,
  allowedSlippage,
}: {
  trade: Trade<Currency, Currency, TradeType>
  slippageAdjustedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  isEnoughInputBalance: boolean
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
  allowedSlippage: number
}) {
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()

  const { priceImpactWithoutFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  const inputTextColor =
    showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT && isEnoughInputBalance
      ? 'primary'
      : trade.tradeType === TradeType.EXACT_OUTPUT && !isEnoughInputBalance
      ? 'failure'
      : 'text'

  const amount =
    trade.tradeType === TradeType.EXACT_INPUT
      ? slippageAdjustedAmounts[Field.OUTPUT]?.toFixed(4)
      : slippageAdjustedAmounts[Field.INPUT]?.toFixed(4)
  const symbol =
    trade.tradeType === TradeType.EXACT_INPUT ? trade.outputAmount.currency.symbol : trade.inputAmount.currency.symbol

  const tradeInfoText =
    trade.tradeType === TradeType.EXACT_INPUT
      ? t('Output is estimated. You will receive at least %amount% %symbol% or the transaction will revert.', {
          amount,
          symbol,
        })
      : t('Input is estimated. You will sell at most %amount% %symbol% or the transaction will revert.', {
          amount,
          symbol,
        })

  const [estimatedText, transactionRevertText] = tradeInfoText.split(`${amount} ${symbol}`)

  const truncatedRecipient = recipient ? truncateHash(recipient) : ''

  const recipientInfoText = t('Output will be sent to %recipient%', {
    recipient: truncatedRecipient,
  })

  const [recipientSentToText, postSentToText] = recipientInfoText.split(truncatedRecipient)

  return (
    <AutoColumn gap="md">
      <Flex flexDirection="column">
        <Box>
          <Text color="from">{t('From')}</Text>
          <Flex className="input-box" alignItems="center" mt="10px">
            <CurrencyLogo currency={trade.inputAmount.currency} size={isMobile ? '24px' : '36px'} />
            <TruncatedText fontSize={isMobile ? '18px' : '20px'} color={inputTextColor}>
              {trade.inputAmount.toFixed(4)}
            </TruncatedText>
            <Text fontSize={isMobile ? '18px' : '20px'} ml="4px">
              {trade.inputAmount.currency.symbol}
            </Text>
          </Flex>
        </Box>
        <Box mt="30px">
          <Text color="from">{t('To')}</Text>
          <Flex className="input-box" alignItems="center" mt="10px">
            <CurrencyLogo currency={trade.outputAmount.currency} size={isMobile ? '24px' : '36px'} />
            <TruncatedText
              fontSize={isMobile ? '18px' : '20px'}
              color={
                priceImpactSeverity > 2
                  ? 'primary'
                  : showAcceptChanges && trade.tradeType === TradeType.EXACT_INPUT
                  ? 'primary'
                  : 'text'
              }
            >
              {trade.outputAmount.toFixed(4)}
            </TruncatedText>
            <Text fontSize={isMobile ? '18px' : '20px'} ml="4px">
              {trade.outputAmount.currency.symbol}
            </Text>
          </Flex>
        </Box>
      </Flex>
      {showAcceptChanges ? (
        <SwapShowAcceptChanges justify="flex-start" gap="0">
          <RowBetween>
            <RowFixed>
              <ErrorIcon mr="8px" color="green" />
              <Text bold> {t('Price Updated')}</Text>
            </RowFixed>
            <Button
              variant="text"
              style={{ background: '#00C3FF', color: '#fff', borderRadius: '8px' }}
              onClick={onAcceptChanges}
            >
              {t('Accept')}
            </Button>
          </RowBetween>
        </SwapShowAcceptChanges>
      ) : null}
      <AutoColumn justify="flex-start" gap="sm" style={{ paddingTop: '10px' }}>
        {/* <RowFixed style={{ width: '100%' }}>
          <Text color="primary">
            {t('Slippage Tolerance')}
          </Text>
          <Text bold color="primary" ml="auto" textAlign="end">
            {`${basisPointsToPercent(allowedSlippage).toFixed(1)}%`}
          </Text>
        </RowFixed> */}
        {trade.tradeType === TradeType.EXACT_OUTPUT && !isEnoughInputBalance && (
          <Text small color="warning" textAlign="left" style={{ width: '100%' }}>
            {t('Insufficient input token balance. Your transaction may fail.')}
          </Text>
        )}
        <Text small color="warning" textAlign="left" style={{ width: '100%' }}>
          {tradeInfoText}
          {/* {estimatedText}
          {transactionRevertText} */}
        </Text>
      </AutoColumn>
      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm">
          <Text color="textSubtle">
            {recipientSentToText}
            <b title={recipient}>{truncatedRecipient}</b>
            {postSentToText}
          </Text>
        </AutoColumn>
      ) : null}
    </AutoColumn>
  )
}
